import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { styled, Typography } from "@mui/material";
import { CustomButton } from "../../../components/ui/Button";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import * as React from "react";
import Slide from "@mui/material/Slide";

const BootstrapDialog = styled(Dialog)(({ theme, width }) => ({
  "& .MuiDialog-paper": {
    background: "transparent",
    boxShadow: "none",
    minWidth: width || "400px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    backgroundColor: "#ffffff",
    borderRadius: "10px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} unmountOnExit />;
});
const InfoPopup = ({ open, handleClose, handleOK }) => {
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition} keepMounted>
      <DialogTitle sx={{ m: 1, p: 2 }} id="customized-dialog-title">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="small"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
            backgroundColor: "#fff",
            borderRadius: "15px",
            width: "28px",
            height: "28px",
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <CloseIcon sx={{ color: "#ff0000" }} size="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Box mb={2} display={"flex"} alignItems={"center"}>
          <Typography variant="h4" mr={1}>
            Note
          </Typography>
          <ReceiptLongOutlinedIcon sx={{ color: "#1677ff" }} />
        </Box>
        <Box>
          <Typography variant="h6">
            Before proceeding to create contracts, please check if :
            <br />
            1. MI records for the contract year has been created in SFDC <br />
            2. Sales target is updated if there is a new variety to be included for forecast information <br />
            3. Account contact details needs update <br />
            4. New account needs to be added
          </Typography>
        </Box>

        <Box display="flex" justifyContent={"flex-end"} sx={{ width: "100%", mt: 1 }}>
          <CustomButton
            label="Proceed"
            sx={{
              color: "#fff",
              borderRadius: "25px",
              backgroundColor: "rgb(0, 145, 223)",
              opacity: "1",
              "&:hover": {
                backgroundColor: "#0D47A1",
              },
              width: "125px",
              height: "32px",
            }}
            handleClick={handleOK}
            className="cus-btn-arrow"
            labelClassName={"label"}
          ></CustomButton>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};
export default InfoPopup;
