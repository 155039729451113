// Routes.js
// import { lazy, Suspense } from "react";
import { Suspense, useEffect } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
// import { Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import Layout from "../layouts";
import PublicRoute from "./PublicRoutes";
import Toastr from "../components/ui/Toastr";
import FlashScreen from "./FlashScreen";
import Contracts from "../pages/PPP/Contracts";
import { ADMIN_ROLE, CMM_ROLE } from "../constants";
// const CreateContracts = lazy(() => import("../pages/PPP/Contracts/CreateContracts"))
import CreateContracts from "../pages/PPP/Contracts/CreateContracts";
import Dashboard from "../pages/Auth/Dashboard";
import Login from "../pages/Login";
import CustomMap from "../pages/Trial";
import PPPDashboard from "../pages/PPP/Dashboard/index";
import Analytics from "../pages/Analytics";
import ConditionalUnconditional from "../pages/PPP/Contracts/ConditionalUnconditional";
import DemandGeneration from "../pages/PPP/Contracts/Activities/Criteria/DemandGeneration/index";
import OfflineMarketing from "../pages/PPP/Contracts/Activities/Criteria/OfflineMarketingActivities/index";
import OnlineMarketing from "../pages/PPP/Contracts/Activities/Criteria/OnlineMarketingActivities/index";
import authorization from "../utils/authorization";

// const Dashboard = lazy(() => import("../pages/Auth/Dashboard"));
// const Login = lazy(() => import("../pages/Login"));
// const CustomMap = lazy(() => import("../pages/Trial"));
// const Analytics = lazy(() => import("../pages/Analytics"));
// const PPPContracts = lazy(() => import("../pages/PPP/Contracts"))
// const PPPDashboard = lazy(() => import("../pages/PPP/Dashboard/index"));
// const ConditionalUnconditional = lazy(() =>
//   import("../pages/PPP/Contracts/ConditionalUnconditional")
// );
// const Activities = lazy(()=> import("../pages/PPP/Contracts/Activities/index"));
// const ActivitiesCriteria = lazy(()=> import("../pages/PPP/Contracts/Activities/Criteria/index"));
// const DemandGeneration = lazy(() =>
//   import("../pages/PPP/Contracts/Activities/Criteria/DemandGeneration/index")
// );
// const OfflineMarketing = lazy(() =>
//   import(
//     "../pages/PPP/Contracts/Activities/Criteria/OfflineMarketingActivities/index"
//   )
// );
// const OnlineMarketing = lazy(() =>
//   import(
//     "../pages/PPP/Contracts/Activities/Criteria/OnlineMarketingActivities/index"
//   )
// );

const AuthCheck = () => {
  const navigate = useNavigate();
  const isLoggedIn = authorization.isLoggedIn();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  return null;
};


let routerN = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<Layout />}>
        {/* Private Routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute roles={[ADMIN_ROLE, CMM_ROLE]}>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/trial-location"
          element={
            <PrivateRoute roles={[ADMIN_ROLE]}>
              <CustomMap />
            </PrivateRoute>
          }
        />
        <Route
          path="/target-dashboard"
          element={
            <PrivateRoute roles={[ADMIN_ROLE]}>
              <Analytics />
            </PrivateRoute>
          }
        />
        <Route
          path="/ppp-dashboard"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <PPPDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/contracts"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <Contracts />
            </PrivateRoute>
          }
        />
        <Route
          path="/editcontract"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <Contracts />
            </PrivateRoute>
          }
        />
        <Route
          path="/createContracts"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <CreateContracts />
            </PrivateRoute>
          }
        />
        <Route
          path="/ppp-conditional-unconditional"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <ConditionalUnconditional />
            </PrivateRoute>
          }
        />
        <Route
          path="/demand-generation"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <DemandGeneration />
            </PrivateRoute>
          }
        />
        <Route
          path="/offline-marketing-activities"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <OfflineMarketing />
            </PrivateRoute>
          }
        />
        <Route
          path="/online-marketing-activities"
          element={
            <PrivateRoute roles={[CMM_ROLE]}>
              <OnlineMarketing />
            </PrivateRoute>
          }
        />
      </Route>

      {/* Public Routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      {/* URL is not applicable after redirect dashboard page */}
      <Route path="*" element={<div style={{ fontSize: 15 }}>{'Error in access! url not found.'}</div>} />
      {/* <Route path="*" element={<div style={{fontSize:15}}>{'Error in access! url not found.'}</div>} /> */}
      <Route path="/" element={<AuthCheck />} />
    </>
  )
);

const AppRoutes = () => {
  return (
    <>
      <Suspense fallback={<FlashScreen />}>
        <Toastr />
        <RouterProvider router={routerN}></RouterProvider>
      </Suspense>
    </>
  );
};

export default AppRoutes;
