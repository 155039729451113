import axios from "axios";
import Authorization from "../utils/authorization";
import { apiURL } from "./apiUrlConfig";
import LocalStorage from "../utils/localStorage";
import { decryption,encryption } from "../utils/helper";

const BASE_API_PATH = process.env.REACT_APP_API;

const ALLOW_ENCY = process.env.REACT_APP_API_ENCRYPTION || 'TRUE';


/**
 * Set header
 */
function headers(image) {
  let headers = {
    Accept: "*/*",
    Pragma: "no-caches",
    "Cache-Control": "no-cache, no-store",
    "content-type": "application/json; charset=utf-8",
    // secure: true,
  };

  // if (image) {
  //   headers.delete("content-type");
  // }
  if(ALLOW_ENCY === 'TRUE') {
    headers = {
      ...headers,
    secure: true,
    };
  }

  const accessToken = Authorization.getAccessToken();
  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return headers;
}

/**
 * Format url
 * @param {string} path
 */
function getUrl(path) {
  const url = `${BASE_API_PATH}/${path}`;
  return url;
}

/**
 * get base api url
 * @param {object} data
 * @param {string} type
 */
// function getRequestBody(data, type) {
//   var body;
//   switch (type) {
//     case "Raw":
//       body = data;
//       break;
//     case "Json":
//       body =data;
//       break;
//     case "Image":
//       body = data;
//       break;
//     default:
//       body = new FormData();
//       Object.keys(data).forEach((key) => {
//         body.append(key, data[key]);
//       });
//       break;
//   }
//   return body;
// }

/**
 * Validate response
 * @param {object} response
 * @param {object} request
 */
async function validateResponse(response, request) {
  if (response.status === 401) {
    return await getAzureToken();
  } else if (response.status === 406) {
    Authorization.logout();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    return response.then((err) => {
      throw err;
    });
  }

  return await responseParser(response);
}

async function responseParser(response) {
  if (response.status >= 400 && response.status < 600) {
    return response.then((err) => {
      throw err;
    });
  }
  // check for header and send the content
  if (response.headers.get("content-type").indexOf("application/json") !== -1) {
    // checking response header
    let data = await response?.data;
    return data;
  } else {
    let responseData;
    if (response?.data) {
      if(ALLOW_ENCY === 'TRUE') {
        responseData = await decryption(response?.data);
      }else{
        responseData = response?.data;
      }

      // const decryptedData = await decryption(response?.data);
      return responseData && JSON.parse(responseData);
    }
    // return response.blob();
  }
}
/**
 * Validate Error
 * @param {object} errorResponse
 * @param {object} request
 */
// async function validateError(errorResponse, request) {
//   if (errorResponse?.response?.status === 401) {
//     await getAzureToken();
//     setTimeout(() => {
//       window.location.reload();
//     }, 1000);
//   } else {
//     throw errorResponse;
//   }
// }

/**
 * Refresh token
 * @param {object} request
 */
// async function refresToken(request) {
//   const body = JSON.stringify({
//     refresh_token: Authorization.getRefreshToken(),
//   });
//   // Refresh token api call
//   const res = await axios({
//     method: "POST",
//     headers: headers(),
//     data: body,
//     url: getUrl("refresh-token"),
//   });

//   if (res.status >= 400 && res.status < 600) {
//     Authorization.logout();
//     setTimeout(() => {
//       window.location.reload();
//     }, 1000);
//     return await res.json().then((err) => {
//       err.message = "Session Expired";
//       throw err;
//     });
//   } else {
//     const token = await res.json();
//     Authorization.login(token);
//     Authorization.setAuthUser();
//     const { url, queryParams, method, data } = request;
//     return await axios({
//       method,
//       headers: headers(),
//       data: data,
//       url: getUrl(url),
//       params: queryParams,
//     }).then(async (response) => {
//       return await responseParser(response);
//     });
//   }
// }

/**
 * Get request
 * @param {object} request
 */
export async function getRequest(request) {
  const { url, queryParams } = request;
  return await axios({
    method: "GET",
    headers: headers(),
    url: getUrl(url),
    params: queryParams,
  }).then((res) => validateResponse(res, { ...request, method: "GET" }));
}

/**
 * Post or Put request
 * @param {object} request
 */
export async function saveOrUpdateRequest(request) {
  const { url, data, method, queryParams } = request;
  return await axios({
    method,
    headers: headers(),
    data: data,
    url: getUrl(url),
    params: queryParams,
  }).then((res) => validateResponse(res, request));
}
export async function uploadRequest(request) {
  const { url, data, method, queryParams } = request;
  return await axios({
    method,
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data,
    url: getUrl(url),
    params: queryParams,
  }).then((res) => validateResponse(res, request));
}

/**
 * Image upload
 * @param {object} request
 */
export async function imageUpload(request) {
  const { url, data, method, queryParams } = request;
  return await axios({
    method,
    headers: headers("image"),
    data: data,
    url: getUrl(url),
    params: queryParams,
  }).then((res) => validateResponse(res, request));
}

/**
 * Delete request
 * @param {object} request
 */
export async function deleteRequest(request) {
  const { url, data, queryParams } = request;
  return await axios({
    method: "DELETE",
    headers: headers(),
    data: data,
    url: getUrl(url),
    params: queryParams,
  }).then((res) => validateResponse(res, { ...request, method: "DELETE" }));
}

/**
 * Get request for azure token
 * @param {object} request
 */
export async function getAzureToken() {
  const { azureToken } = apiURL;
  const url = `${process.env.REACT_APP_OAUTH}/${azureToken}`;
  const request = {
    method: "GET",
    headers: headers(),
    url: url,
    params: {
      apiKey: process.env.REACT_APP_API_KEY,
      environment: process.env.REACT_APP_ENVIRONMENT,
    },
  };
  return await axios(request).then((res) => {
    let token = res?.data?.body.access_token;
    LocalStorage.setLocalStorageValue("accessToken", token);
    return validateResponse(res, { ...request });
  });
}

const checkFileFormData = (configFormData) => {
  const getconfigFormdata = configFormData
  let formKey = ''
  if(getconfigFormdata instanceof FormData ){
    for (let key of getconfigFormdata) {
      formKey = key[0]
    }
  }
  return formKey
}

axios.interceptors.request.use(async function (config) {
 let keyName = await checkFileFormData(config?.data)

  if(ALLOW_ENCY === 'TRUE' && keyName !== 'file') {
    const decryptPayload = await encryption(config?.data);
    config.data = {'encd':decryptPayload}
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// To avoid multiple API Calls for Refresh token
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios?.interceptors?.response?.use(
  (response) => {
    return response;
  },
  async (err) => {
    const originalRequest = err?.config;
    if (err?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        getAzureToken()
          .then((res) => {
            let token = res?.body?.access_token;
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            originalRequest.headers["Authorization"] = "Bearer " + token;
            processQueue(null, token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);
